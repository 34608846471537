import { readState } from "@/__main__/app-state.mjs";
import { IS_NODE } from "@/__main__/constants.mjs";
import blitzMessage, {
  EVENTS,
  handleMessage,
  initEvents,
} from "@/__main__/ipc-core.mjs";
import { writeSettings } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import type { LolOverlaySettings } from "@/game-lol/definition.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition.mjs";
import lolClient from "@/game-lol/utils/lol-client.mjs";
import { listenClientEvents } from "@/game-lol/utils/lol-client-api.mjs";
import { EVENT_OVERLAY_VISIBILITY } from "@/hub-overlays/handle-messages.mjs";
import { OVERLAYS } from "@/library/actions.mjs";
import clone from "@/util/clone.mjs";
import deepMerge from "@/util/deep-merge.mjs";
import { devDebug, devError } from "@/util/dev.mjs";
import diff from "@/util/diff.mjs";
import globals from "@/util/global-whitelist.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

interface Timers {
  pollForLCU?: Timeout;
}

const timers: Timers = {};
const POLLING_LCU = 4000;
const RETRY_LCU = 1500;

// Poll for LCU connection info (legacy implementation + Mac).
async function pollForLCU() {
  if (IS_NODE) return;
  timers.pollForLCU = setTimeout(pollForLCU, POLLING_LCU);
  if (lolClient.connectionInfo) return;
  const connectionInfo = await blitzMessage(EVENTS.LCU_CONNECTION_INFO);
  lolClient.connectionInfo = connectionInfo;
}

// WMI-based Process Detection, Windows only!
// This method avoids polling while idle.
const LOL_EXE = "LeagueClient.exe";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function detectLCU() {
  // Initial attempt to get connection info.
  const connectionInfo = await blitzMessage(EVENTS.LCU_CONNECTION_INFO);
  lolClient.connectionInfo = connectionInfo;

  try {
    const result = await blitzMessage(EVENTS.WATCH_PROCESS, LOL_EXE);
    devDebug(`Watching ${LOL_EXE}`, result);
  } catch (error) {
    devError(`failed to watch ${LOL_EXE}.`, error);
  }

  handleMessage(
    (EVENTS.emitters as unknown as Record<string, string>)?.PROCESS_START,
    async ({ executable_name: name, command_line: args }) => {
      if (name !== LOL_EXE) return;

      devDebug("LoL process started!", args);

      let connectionInfo,
        attempts = 0;

      while (!connectionInfo && attempts < 10) {
        connectionInfo = await blitzMessage(EVENTS.LCU_CONNECTION_INFO);
        if (!connectionInfo) {
          attempts++;
          await new Promise((resolve) => {
            setTimeout(resolve, RETRY_LCU);
          });
        }
      }

      // rito's websocket is not immediately available -- keep trying
      // until we get a connection.
      function retry() {
        // Ensure connection is assigned after it is unset, but also wait a
        // bit before attempting again.
        setTimeout(async () => {
          connectionInfo = await blitzMessage(EVENTS.LCU_CONNECTION_INFO);
          lolClient.connectionInfo = connectionInfo;

          // Since this is a new connection instance, attach listener again.
          lolClient._ws.addEventListener("error", retry);
        }, RETRY_LCU);
      }

      lolClient.connectionInfo = connectionInfo;

      lolClient._ws.addEventListener("error", retry);
      lolClient._ws.addEventListener("open", () => {
        devDebug("LCU connection initialized!");
        lolClient._ws.removeEventListener("error", retry);
      });
    },
  );

  handleMessage(
    (EVENTS.emitters as unknown as Record<string, string>)?.PROCESS_STOP,
    ({ executable_name: name }) => {
      if (name !== LOL_EXE) return;
      devDebug("LoL process ended!");
    },
  );
}

async function initLOL() {
  await initEvents;
  listenClientEvents();

  // All of the following is for the main window.
  if (isRouteOverlay(globals.location?.pathname)) return;

  /**
   * Register a handleMessage listener for when overlay settings
   * are changed in-game.
   */
  handleMessage(
    EVENTS.LOL_OVERLAY_SETTINGS,
    (overlaySettings: LolOverlaySettings) => {
      const prevOverlaySettings = clone(
        readState.settings[GAME_SHORT_NAMES[GAME_SYMBOL_LOL]][OVERLAYS],
      );

      const changes = diff(prevOverlaySettings, overlaySettings);

      const nextOverlaySettings = deepMerge(
        prevOverlaySettings,
        changes,
        false,
      );

      writeSettings(
        [GAME_SHORT_NAMES[GAME_SYMBOL_LOL], OVERLAYS],
        nextOverlaySettings,
      );

      eventBus.emit(EVENT_OVERLAY_VISIBILITY, {
        game: GAME_SHORT_NAMES[GAME_SYMBOL_LOL],
        visible: true,
        hasWebOverlay: false,
        trigger: EVENTS.LOL_OVERLAY_SETTINGS,
      });
    },
  );

  if (globals.navigator?.platform === "Win32") {
    // TEMP: enable both for now, we wanna diagnose why WMI doesn't work sometimes.
    // It should only be one or the other.
    detectLCU();
    pollForLCU();
  } else {
    pollForLCU();
  }
}

export default initLOL;
